import React from "react";
import Obfuscate from "react-obfuscate";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";
import { Link } from "gatsby";

const DatenschutzPage = ({ data }, location) => {
  return (
    <Layout>
      <SEO
        title="Datenschutzhinweis"
        keywords={[`michael gwerder`, `datenschutzhinweis`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>Datenschutzhinweis</h2>
          <h4>Geltungsbereich</h4>
          <p>
            Diese Datenschutzerklärung gilt für die Webseite
            https://www.michaelgwerder.ch. Es gilt das Schweizer
            Datenschutzrecht (DSG).
          </p>
          <h4>
            Bearbeitung personenbezogener Daten; Art, Zweck und deren Verwendung
          </h4>
          <h5>Beim Besuch der Webseite</h5>
          <p>
            Beim Aufrufen meiner Webseite werden Informationen in sogenannten
            Logfiles auf Servern des Hostingproviders temporär gespeichert. Dies
            sind Informationen, welche der Browser deiner Endgeräts automatisch
            versendet. Namentlich:
          </p>
          <ul>
            <li>IP-Adresse des kontaktierenden Geräts</li>
            <li>Datum und Zeit</li>
            <li>URL der aufgerufenen Seite</li>
            <li>Referrer-URL</li>
            <li>Browser und weitere Geräteinformationen</li>
          </ul>
          <p>Die genannten Daten werden zu folgenden Zwecken verarbeitet:</p>
          <ul>
            <li>Verbindungsaufbau der Webseite</li>
            <li>Nutzung meiner Webseite</li>
            <li>Systemsicherheit und -stabilität</li>
          </ul>
          <h4>
            Weitergabe von Daten an Dritte (inkl. gemeinsame Verantwortliche und
            Auftragsdatenverarbeiter)
          </h4>
          <p>
            Eine Übermittlung diener persönlichen Daten an Dritte zu anderen als
            den aufgeführten Zwecken findet nicht statt.
          </p>
          <h4>Cookies</h4>
          <p>
            Ich setze aus meiner Webseite Cookies ein. Hierbei handelt es sich
            um kleine Dateien, die dein Browser automatisch erstellt und die auf
            deinem Endgerät (Laptop, Tables, Smartphone, etc.) gespeichert
            werden, wenn du meine Seite besuchst. Ein Cookie bedeutet nicht in
            jedem Fall, dass du identifizierbar bist.
          </p>
          <p>
            Der Einsatz von Cookies dient einerseits dazu, die
            Nutzungshäufigkeit, Nutzerzahl und Verhaltensweise auf meiner
            Webseite zu protokollieren, die Sicherheit der Webseiten-Nutzung zu
            erhöhen und das Informationeangebot nutzerfreundlich auszugestalten.
          </p>
          <p>
            Darüber hinaus setzt diese Webseite zur Optimierung der
            Benutzerfreundlichkeit temporäre Cookies ein, die für einen
            bestimmten festgelegten Zeitraum auf deinem Endgerät gespeichert
            werden. Besuchst du meine Webseite erneut, wird automatisch erkannt,
            dass du schon einmal hier warst und welche Eingaben und
            Einstellungen du getätigt hast.
          </p>
          <p>
            Du kannst deine Browsereinstellungen so konfigurieren, dass keine
            Cookies mehr auf deinem Endgerät gespeichert werden. Die
            vollständige Deaktivierung der Cookies kann aber dazu führen, dass
            diese Webseite nicht mehr genutzt werden kann.
          </p>
          <p>
            Mit der fortgesetzten Nutzung meiner Webseite und/oder der
            Zustimmung dieser Datenschutzerklärung willigst du ein, dass Cookies
            gesetzt und damit personenbezogene Nutzungsdaten erhoben,
            gespeichert und genutzt werden, auch über das Ende der
            Browser-Sitzung hinaus. Diese Einwilligung kannst du jederzeit
            widerrufen, indem du die Browsereinstellung zur Verweigerung von
            Cookies von Drittanbierern aktivierst.
          </p>
          <h4>Analyse-Tools</h4>
          <h5>Google Analytics</h5>
          <p>
            Meine Webseite verwendet den Webanalysedienst Google Analytics von
            Google Inc., USA. Google Analytics verwendet Cookies (siehe unter
            Cookies). Diese ermöglichen eine Analyse der Nutzung meiner
            Webseiteangebots inkl. IP-Adressen durch Google in den USA.
          </p>
          <p>
            Ich weise darauf hin, dass auf dieser Webseite Google Analytics
            durch den Code für eine anonymisierte Erfassung von IP-Adressen
            erweitert wurde, um eine anonymisierte Erfassung der IP-Adressen zu
            gewährleisten. Nur in Ausnahmefällen wird die volle IP-Adresse an
            einen Server von Google in den USA übertragen und dort gekürzt.
          </p>
          <p>
            Google beachtet die Datenschutzbestimmungen des "Swiss-USA Pricavy
            Shield"-Abkommens und verfügt damit über ein adäquates
            Datenschutzniveau. Google nutzt die gesammelten Informationen, um
            die Nutzuns meiner Webseite für mich auszuwerten, Berichte für mich
            diesbezüglich zu verfassen und andere diesbezügliche
            Dienstleistungen an mich zu erbringen.
          </p>
          <p>
            Mehr erfährst du unter{" "}
            <a
              href="https://www.google.com/intl/de/analytics/privacyoverview.html"
              target="_blank"
              rel="noreferrer"
              title="Google Privacy Overview"
            >
              www.google.com/intl/de/analytics/privacyoverview.html
            </a>
            , insbesondere auch betreffend die Möglichkeit zur Deaktivierung von
            Google Analytics unter{" "}
            <a
              href="https:/http://tools.google.com/dlpage/gaoptout?hl=de"
              rel="noreferrer"
              target="_blank"
              title="Google Analytics Opt Out"
            >
              tools.google.com/dlpage/gaoptout?hl=de
            </a>
            .
          </p>
          <h4>Newsletter</h4>
          <p>
            Mit der Anmeldung zum Newsletter und der Bestätigung zum Erhalt des
            Newsletters werden folgende Personendaten bearbeitet: Name, Vorname,
            E-Mail-Adresse. Die Daten werden zum Zweck bearbeitet, den
            Anmeldenden bei der Anmeldung zu authentisieren und den Anmeldenden
            einen Newsletter zuzustellen, festzustellen, ob und wann er diesen
            und einzelne Artikel darauf geöffnet hat.
          </p>
          <p>
            Die von dir zum Zweck des Newsletter-Bezugs eingegebenen Daten
            werden von Mailchimp bearbeitet. Die Verarbeitung dieser Daten
            erfolgt ausschliesslich auf Grundlage deiner Einwilligung. Du kannst
            diese Einwilligung jederzeit widerrufen, etwa über den
            "Abmelden"-Link im Newsletter. Die bereits erfolgten
            Datenverarbeitungsvorgänge bleiben vom Widerruf unberührt. Wenn ein
            Newsletter mithilfe von Mailchimp versendet wird, kann ich
            feststellen, ob ein Newsletter geöffnet und welche Links angeklickt
            wurden. Mailchimp ermöglicht es mir, Empfänger anhand verschiedener
            Kategorien zu unterteilen. Nähere Informationen erhälst du unter:{" "}
            <a
              href="https://mailchimp.com/legal/privacy/"
              rel="noreferrer"
              target="_blank"
              title="Datenschutzerklärung Mailchimp"
            >
              mailchimp.com/legal/privacy/
            </a>
            .
          </p>
          <h4>Datensicherheit</h4>
          <p>
            Jegliche Daten werden nur über eine mittels HTTPS gesicherte
            Verbindung übertragen. Zugriff auf die Daten hat alleine der
            Datenschutzbeauftragte.
          </p>
          <h4>Aufbewahrungsdauer</h4>
          <p>
            Die Analysedaten auf Google Analytics werden für 26 Monate
            aufbewahrt und danach automatisch gelöscht.
          </p>
          <h4>Kontaktdaten</h4>
          <p>
            Diese Datenschutzerklärung gilt für die Datenverarbeitung durch:
          </p>
          <p>
            Michael Gwerder
            <br />
            <Obfuscate
              email="kontakt@michaelgwerder.ch"
              headers={{
                subject: "Kontakt über Website"
              }}
            />
          </p>
          <p>Datenschutzbeauftragter: Michael Gwerder</p>
          <p>
            Aufsichtsbehörde: Eidgenössischer Öffentlichkeits- und
            Datenschutzbeauftragter, EDÖB
          </p>
          <h4>Aktualität und Änderung dieser Datenschutzerklärung</h4>
          <p>
            Diese Datenschutzerklärung kann jederzeit geändert und angepasst
            werden. Die aktuelle Datenschutzerklärung kann auf{" "}
            <Link to="/datenschutzhinweis" title="Datenschutzhinweis">
              www.michaelgwerder.ch/datenschutzhinweis
            </Link>{" "}
            abgerufen werden.
          </p>
          <p>Berg am Irchel, 14.01.2021, Version 1</p>
        </div>
      </article>
    </Layout>
  );
};

export default DatenschutzPage;
